<template>
  <div id="terminal_device_component">
    <div class="terminal_device">
      <div class="terminal_device__item navigate_button_container">
        <div
          @click="onSelectTab"
          id="info"
          class="navigate_button_container__item"
        >
          <button :class="selectedTab === 'info' ? 'selected' : ''">
            Информация
          </button>
        </div>
        <div
          @click="onSelectTab"
          id="display"
          class="navigate_button_container__item"
        >
          <button :class="selectedTab === 'display' ? 'selected' : ''">
            Дисплей
          </button>
        </div>
        <div
          @click="onSelectTab"
          id="devices"
          class="navigate_button_container__item"
        >
          <button :class="selectedTab === 'devices' ? 'selected' : ''">
            Устройства
          </button>
        </div>
        <div
          @click="onSelectTab"
          id="options"
          class="navigate_button_container__item"
        >
          <button :class="selectedTab === 'options' ? 'selected' : ''">
            Настройки
          </button>
        </div>
      </div>
      <div class="terminal_device__item content_container">
        <Transition name="base" mode="out-in">
          <div
            v-if="selectedTab === 'info'"
            class="content_container__item info_content_container"
          >
            <div class="info_content_container__item">
              <info-container titleRight="Common" :data="getCommandInfo" />
              <div class="info_content_container__item info_container">
                <div class="info_container__item line_container">
                  <div class="line_container__item line_title_container">
                    <div
                      class="line_title_container__item line_title status_container"
                    >
                      <div class="status_container__item status_text">
                        <label>Status</label>
                      </div>
                      <div
                        class="status_container__item status_button"
                        :class="!terminal.online ? 'disable' : ''"
                      >
                        <Transition name="bounce" mode="out-in">
                          <button
                            v-if="!playStatus"
                            @click="terminal.online ? onPlayStatus() : null"
                            title="Monitor status"
                          >
                            <svg
                              fill="currentColor"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="15px"
                              height="15px"
                              viewBox="0 0 408.221 408.221"
                              xml:space="preserve"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <g>
                                  <g>
                                    <path
                                      d="M204.11,0C91.388,0,0,91.388,0,204.111c0,112.725,91.388,204.11,204.11,204.11c112.729,0,204.11-91.385,204.11-204.11 C408.221,91.388,316.839,0,204.11,0z M286.547,229.971l-126.368,72.471c-17.003,9.75-30.781,1.763-30.781-17.834V140.012 c0-19.602,13.777-27.575,30.781-17.827l126.368,72.466C303.551,204.403,303.551,220.217,286.547,229.971z"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                          <div v-else class="stop_button">
                            <button
                              title="Stop monitor status"
                              @click="onStopStatus"
                            >
                              <svg class="spinner" viewBox="0 0 50 50">
                                <circle
                                  :class="`circle ${getStyleStatus}`"
                                  cx="25"
                                  cy="25"
                                  r="23"
                                  fill="none"
                                  stroke-width="4"
                                ></circle>
                              </svg>
                              <div
                                :class="`spinner_container ${getStyleStatus}`"
                              ></div>
                            </button>
                          </div>
                        </Transition>
                      </div>
                    </div>
                  </div>
                  <div
                    class="line_container__item line_value"
                    :title="getCurrentStatus"
                  >
                    <Transition name="base" mode="out-in">
                      <label
                        :key="getCurrentStatus"
                        class="multyline_container__item"
                        >{{ getCurrentStatus }}</label
                      >
                    </Transition>
                  </div>
                </div>
              </div>
            </div>

            <div class="info_content_container__item">
              <info-container
                titleRight="Distributions"
                :data="getDistributionInfo"
              />
            </div>
            <div class="info_content_container__item network_container">
              <div class="network_container__item title">
                <label>Network</label>
              </div>
              <div
                v-for="(el, i) in getNetworkInfo"
                class="network_container__item"
                :key="i"
              >
                <info-container :titleRight="el.title" :data="el.data" />
              </div>
            </div>
            <div class="info_content_container__item">
              <info-container titleRight="Transport" :data="getTransportInfo" />
            </div>
          </div>
          <div
            v-else-if="selectedTab === 'display'"
            class="content_container__item display_content_container"
          >
            <div
              v-for="(el, i) in getDisplayInfo"
              :key="i"
              class="display_content_container__item"
            >
              <display
                :title="el.title"
                :info="el.info"
                :resolutions="el.resolutions"
                :currentResolution="el.currentResolution"
              />
            </div>
            <div
              v-if="getDisplayInfo.length === 0"
              class="display_content_container__item"
            >
              <label>Не найдено</label>
            </div>
          </div>
          <div
            v-else-if="selectedTab === 'devices'"
            class="content_container__item device_content_container"
          >
            <div class="device_content_container__item">
              <info-container titleRight="Hardware" :data="getHardwareInfo" />
            </div>
            <div class="device_content_container__item">
              <info-container titleRight="Inputs" :data="getInputInfo" />
            </div>
            <div class="device_content_container__item touch_screen_container">
              <div class="touch_screen_container__item title">
                <label>Mapping touchscreen</label>
              </div>
              <div v-if="this.terminal.devices.touchDevices.length === 0">
                <label>Отсутствует</label>
              </div>
              <div
                v-for="(el, i) in getTouchscreenInfo"
                :key="i"
                class="touch_screen_container__item"
              >
                <info-container :titleRight="el.title" :data="el.data" />
              </div>
            </div>
            <div class="device_content_container__item storage_container">
              <div class="storage_container__item title">
                <label>Storage</label>
              </div>
              <div
                v-if="getStorageInfo.length > 0"
                class="storage_container__item storage_info_container"
              >
                <div
                  v-for="(storage, i) in getStorageInfo"
                  :key="i"
                  class="storage_info_container__item storage_device_container"
                >
                  <div class="storage_deveice_container__item line_title">
                    {{ storage.title }}
                  </div>
                  <div class="storage_deveice_container__item">
                    <procent-split-line
                      :colors="['#4150b7']"
                      :data="storage.linePartiton"
                    />
                  </div>

                  <div
                    class="storage_deveice_container__item storage_table_container"
                  >
                    <div
                      class="storage_table_container__item table_header_container"
                    >
                      <div class="header_container__item table_line_container">
                        <div class="line_container__item column path_column">
                          path
                        </div>
                        <div class="line_container__item column size_column">
                          size
                        </div>
                        <div class="line_container__item column label_column">
                          label
                        </div>
                        <div
                          class="line_container__item column filesystem_column"
                          style="border-right: unset"
                        >
                          filesystem
                        </div>
                      </div>
                    </div>
                    <div
                      class="storage_table_container__item table_body_container"
                    >
                      <div
                        v-for="(partiton, i) in storage.partitons"
                        :key="i"
                        class="body_container__item table_line_container"
                        :class="!(i % 2) ? 'blue' : 'white'"
                      >
                        <div
                          :title="wrapNullValue(partiton.path, '-')"
                          class="line_container__item column path_column"
                        >
                          {{ wrapNullValue(partiton.path, "-") }}
                        </div>
                        <div
                          :title="wrapNullValue(partiton.size, '-')"
                          class="line_container__item column size_column"
                        >
                          {{ wrapNullValue(partiton.size, "-") }}
                          {{ partiton.size ? "GB" : "" }}
                        </div>
                        <div
                          :title="wrapNullValue(partiton.label, '-')"
                          class="line_container__item column label_column"
                        >
                          {{ wrapNullValue(partiton.label, "-") }}
                        </div>
                        <div
                          :title="wrapNullValue(partiton.filesystem, '-')"
                          class="line_container__item column filesystem_column"
                        >
                          {{ wrapNullValue(partiton.filesystem, "-") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="not_found"><label>Отсутствует</label></div>
            </div>
          </div>
          <div
            v-else-if="selectedTab === 'options'"
            class="content_container__item"
          >
            <setting
              @update-terminal-data="$emit('updateTerminalData')"
              :terminal="terminal"
            />
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { playStatusInterval } from "@/config.js";
import { wrapNullValue } from "@/utils.js";

const playStatusState = {
  WaitingResponse: 0,
  Sleep: 1,
  ResponseOk: 2,
  ResponseError: 3,
};

export default {
  components: {
    "info-container": defineAsyncComponent(() =>
      import("@/components/InfoContainer.vue")
    ),
    "procent-split-line": defineAsyncComponent(() =>
      import("@/components/ProcentSplitLine.vue")
    ),
    display: defineAsyncComponent(() => import("@/components/Display.vue")),
    setting: defineAsyncComponent(() =>
      import("@/components/TerminalSetting.vue")
    ),
  },
  props: {
    terminal: {
      type: Object,
    },
  },
  data() {
    return {
      selectedTab: "info",
      templates: [],
      selectedTemplate: this.terminal.template,
      selectedDistributivesType: "containers",
      selectedDistributivesVersion: "",
      selectedAgent: "",
      distributives: {},
      transportAgents: [],
      playStatus: false,
      receivedStatus: null,
      playStatusInterval: null,
      playStatusState: playStatusState.Sleep,
      blockStatusStateRequest: false,
    };
  },
  methods: {
    wrapNullValue,
    resetState() {
      this.selectedTab = "info";
      clearInterval(this.playStatusInterval);
      this.receivedStatus = null;
      this.playStatus = false;
    },
    onClearDisplayConfig() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/template`,
          method: "POST",
          payload: {
            template: this.selectedTemplate,
            terminalId: this.termina.terminalId,
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set template to terminal)",
            res
          );

          if (res.status === 200 && res.data.statusCode === 200) {
            this.$emit("updateTerminalData");
          }
        })
        .catch((error) => {
          console.log("Error to get terminal list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    // change distributive request
    onApplyDistributive() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/template`,
          method: "POST",
          payload: {
            template: this.selectedTemplate,
            terminalId: this.termina.terminalId,
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set template to terminal)",
            res
          );

          if (res.status === 200 && res.data.statusCode === 200) {
            this.$emit("updateTerminalData");
          }
        })
        .catch((error) => {
          console.log("Error to get terminal list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    // change template request
    onApplyTemplate() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/template`,
          method: "POST",
          payload: {
            template: this.selectedTemplate,
            terminalId: this.terminal.terminalId,
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set template to terminal)",
            res
          );

          if (res.status === 200 && res.data.statusCode === 200) {
            this.$emit("updateTerminalData");
          }
        })
        .catch((error) => {
          console.log("Error to get terminal list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    getCurrentDistrVersionByType() {
      return this.terminal.distributions.filter(
        (el) => el.type === this.selectedDistributivesType
      )[0].distribution;
    },

    onChangeDistributivesType() {
      this.selectedDistributivesVersion = this.getCurrentDistrVersionByType();
    },

    // get distrs request
    getTransport() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/agents`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (transport agent list received)",
            res
          );
          if (res.status === 200 && res.data.statusCode === 200) {
            console.log(res.data.payload.agents);
            this.transportAgents = res.data.payload.agents.map((el) => {
              return el.agent;
            });
          }
        })
        .catch((error) => {
          console.log("Error to get transport agent list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    // get distrs request
    getDistributives() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/distributions/types`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (distr list received)",
            res
          );
          if (res.status === 200 && res.data.statusCode === 200) {
            res.data.payload.loadingDistrTypes.forEach((el) => {
              this.distributives[el.distributionsType] = el.distributions;
            });
          }
        })
        .catch((error) => {
          console.log("Error to get terminal list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    getTemplates() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/templates`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (templates list received)",
            res
          );
          if (res.status === 200) {
            this.templates = res.data.payload.templates.map(
              (el) => el.template
            );
          }
        })
        .catch((error) => {
          console.log("Error to get terminal list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },
    onSelectTab(event) {
      this.selectedTab = event.currentTarget.id;
    },
    converNetMaskToCIR(mask) {
      return mask
        ? mask.split(".").reduce((c, o) => c - Math.log2(256 - +o), 32)
        : null;
    },
    splitToChunks(array, parts) {
      let result = [];
      for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
      }
      return result;
    },
    onPlayStatus() {
      this.playStatus = true;
      // this.getTerminalStatus();
      this.playStatusInterval = setInterval(
        this.getTerminalStatus,
        1000 * playStatusInterval
      );
    },
    onStopStatus() {
      this.playStatus = false;
      clearInterval(this.playStatusInterval);
    },
    getTerminalStatus() {
      if (this.blockStatusStateRequest) {
        return;
      }
      this.playStatusState = playStatusState.WaitingResponse;
      this.blockStatusStateRequest = true;
      this.$http
        .post(
          `${this.$backEndUrl}/v1/proxy/monitoring`,
          {
            url: `/api/v1/terminal?id=${this.terminal.terminalId}`,
            method: "GET",
            payload: {},
            headers: {},
          },
          { timeout: 5000 }
        )
        .then((res) => {
          console.log("Response from proxy monitoring obtained", res);
          if (res.data.statusCode === 200) {
            this.playStatusState = playStatusState.ResponseOk;
            console.log("Response from monitoring obtained", res.data.payload);
            let status = res.data.payload.openMetrics.find(
              (el) => el.name == "terminal_exporter_terminal_notify_status"
            );

            if (status) {
              this.receivedStatus = status.values[0].slice(-1)[0].status;
            }
          }
        })
        .catch((error) => {
          console.log("Error to get status data", error);
          this.playStatusState = playStatusState.ResponseError;
        })
        .finally(() => {
          setTimeout(() => {
            this.playStatusState = playStatusState.Sleep;
          }, 1000);
          this.blockStatusStateRequest = false;
        });
    },
  },

  computed: {
    getTouchscreenInfo() {
      const data = [];
      for (const el of this.terminal.devices.touchDevices) {
        data.push({
          title: el.name,
          data: [{ title: "Output", value: el.mapToOutput }],
        });
      }
      return data;
    },
    getHardwareInfo() {
      return [
        { title: "Vendor", value: this.terminal.devices.boardVendor },
        { title: "Model", value: this.terminal.devices.boardName },
        { title: "CPU", value: this.terminal.devices.cpuName },
        { title: "RAM", value: this.terminal.devices.totalMemSizeMB },
      ];
    },
    getInputInfo() {
      const data = [];
      for (const el of this.terminal.devices.touchDevices) {
        data.push({ title: "Touchscreen", value: el.name });
      }
      if (data.length === 0) {
        data.push({ title: "Touchscreen", value: null });
      }

      let mouse = this.terminal.devices.inputDevices.find((e) => e.isMouse);
      mouse = mouse ? mouse.model : null;
      let keyboard = this.terminal.devices.inputDevices.find(
        (e) => e.isKeyboard
      );
      keyboard = keyboard ? keyboard.model : null;
      data.push(
        { title: "Mouse", value: mouse },
        { title: "Keyboard", value: keyboard }
      );
      return data;
    },
    getDisplayInfo() {
      const displays = [];
      for (const el of this.terminal.displays.outputs) {
        el.supportedResolutions.sort(
          (a, b) => b.split("x")[0] - a.split("x")[0]
        );
        displays.push({
          title: el.name,
          currentResolution: el.currentResolution,
          resolutions: this.splitToChunks(el.supportedResolutions, 3),
          info: [
            { title: "Output", value: el.output },
            { title: "Serial", value: el.serial },
            {
              title: "Turned on",
              value: el.turnedOn,
              valueColor: el.turnedOn ? "#5BC367" : "#F44C4B",
            },
            { title: "Rotation", value: el.rotation },
            { title: "Windows", value: el.windows.join(", ") },
          ],
        });
      }
      return displays;
    },
    getCommandInfo() {
      let onlineColor = null;
      let terminalStateColor = null;
      switch (this.terminal.terminalState) {
        case "OK":
          terminalStateColor = "#5BC367";
          break;
        case "BLOCKED":
          terminalStateColor = "#F44C4B";
          break;
      }

      switch (this.terminal.online) {
        case true:
          onlineColor = "#5BC367";
          break;
        case false:
          onlineColor = "#F44C4B";
          break;
      }

      return [
        { title: "Mac", value: this.terminal.terminalMac },
        {
          title: "Online",
          value: this.terminal.online,
          valueColor: onlineColor,
        },
        {
          title: "Last connect",
          value: this.terminal.lastConnect,
        },
        {
          title: "Boot time",
          value: this.terminal.rootfsBootTime,
        },
        {
          title: "Rootfs version",
          value: this.terminal.rootfsVersion,
        },
        {
          title: "Kernel version",
          value: this.terminal.kernelVersion,
        },
        // { title: "Status", value: this.terminal.status },
        { title: "Template", value: this.terminal.template },
        { title: "TerminalState", value: this.terminal.terminalState, valueColor: terminalStateColor },
      ];
    },
    getDistributionInfo() {
      let [containers, initrd, kernel] = [null, null, null];
      for (const el of this.terminal.distributions) {
        switch (el.type) {
          case "containers":
            containers = el.distribution;
            break;
          case "initrd":
            initrd = el.distribution;
            break;
          case "kernel":
            kernel = el.distribution;
            break;
        }
      }
      return [
        {
          title: "Containers",
          value: containers,
        },
        { title: "Initrd", value: initrd },
        { title: "Kernel", value: kernel },
      ];
    },
    getNetworkInfo() {
      const data = [];
      for (const el of this.terminal.networks) {
        const ips = [{ title: "speed", value: el.speed }];

        // extract ips
        el.ipAddr.forEach((e) => {
          ips.push({
            title: "IPv4",
            value: `${e.ipv4}/${this.converNetMaskToCIR(e.netmask)}`,
          });
        });

        // push inteface
        data.push({
          title: el.nicName,
          data: ips,
        });
      }
      return data;
    },
    getStorageInfo() {
      const data = [];
      console.log(this.terminal.devices.storages);
      for (const el of this.terminal.devices.storages) {
        data.push({
          title: `${el.path}  ${el.model}  ${el.size} GB`,
          linePartiton: el.partitions.map((elPar) => {
            return {
              main_title: elPar.path,
              sub_title: `${elPar.size}GB`,
              weight: elPar.size,
            };
          }),
          partitons: el.partitions,
        });
      }
      return data;
    },
    getTransportInfo() {
      let stateColor = null;
      switch (this.terminal.transport.state) {
        case "OK":
          stateColor = "#5BC367";
          break;
        case "ERROR":
          stateColor = "#F44C4B";
          break;
        case "INPROCESS":
          stateColor = "#F87D02";
          break;
        case "DELETED":
          stateColor = "#02A7FA";
          break;
      }
      return [
        {
          title: "Agent",
          value: this.terminal.transport.agent,
        },
        {
          title: "State",
          value: this.terminal.transport.state,
          valueColor: stateColor,
        },
        {
          title: "Created",
          value: this.terminal.transport.creatingDttm,
        },
      ];
    },
    getCurrentStatus() {
      const status = this.receivedStatus || this.terminal.status;

      return status ? status : "Неизвестно";
    },

    getStyleStatus() {
      switch (this.playStatusState) {
        case playStatusState.Sleep:
          return "";

        case playStatusState.WaitingResponse:
          return "waiting";
        case playStatusState.ResponseOk:
          return "ok";
        case playStatusState.ResponseError:
          return "error";
      }
      return "";
    },
  },

  mounted() {
    this.getTemplates();
    this.getDistributives();
    this.selectedDistributivesVersion = this.getCurrentDistrVersionByType();
    this.getTransport();
  },

  watch: {
    "terminal.terminalId"() {
      this.resetState();
    },
  },

  beforeUnmount() {
    if (this.receivedStatus) {
      this.$emit("updateTerminalData", false);
    }
    this.resetState();
  },
};
</script>

<style lang="less">
@import "../assets/styles/inputs.less";
@import "../assets/styles/buttons.less";
@import "../assets/styles/animations.less";

.terminal_device_component {
  display: flex;
  flex-direction: column;
}

.navigate_button_container {
  display: flex;
  padding: 25px 20px 34px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  button {
    all: unset;
    cursor: pointer;
    background: #ffffff;
    border: 2px solid #4150b7;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 15px;
    transition: 0.7s;
    &.selected {
      background-color: #4150b7;
      color: #ffffff;
      transition: 0.7s;
    }
  }
}

.content_container {
  padding: 0px 25px;
  overflow-y: scroll;
  max-height: 70vh;
}

.info_content_container {
  display: flex;
  flex-direction: column;

  .info_content_container__item {
    margin-bottom: 20px;
  }
}

.network_container {
  display: flex;
  flex-direction: column;
  .network_container__item {
    &.title {
      margin-bottom: 20px;
    }
  }
}

.storage_container {
  display: flex;
  flex-direction: column;
  .storage_container__item {
    &.title {
      margin-bottom: 10px;
    }
  }
  .storage_info_container {
    display: flex;
    flex-direction: column;
    .storage_device_container {
      width: 700px;
      .line_title {
        margin: 10px 0px 10px 5px;
      }
      .storage_table_container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .table_header_container {
          display: flex;
          font-size: 15px;
          .column:extend(.column) {
            border-right: 1px solid #4150b7;
          }
        }
        .table_line_container {
          width: 100%;
          display: flex;
          margin-bottom: 3px;
          margin-left: 5px;
          font-size: 15px;
          .column {
            margin-left: 9px;
            display: flex;
            justify-content: flex-start;

            &.path_column {
              width: 25%;
            }
            &.size_column {
              width: 25%;
            }
            &.label_column {
              width: 25%;
            }
            &.filesystem_column {
              width: 25%;
            }
          }
        }
        .table_body_container {
          .table_line_container {
            font-size: 14px;
            &.white {
              background: none;
            }
            &.blue {
              background: #f1f6ff;
            }
          }
        }
      }
    }
  }
}

.display_content_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .display_content_container__item {
    padding: 5px 0px;
  }
}

.device_content_container {
  display: flex;
  flex-direction: column;
  .device_content_container__item {
    margin-bottom: 25px;
  }
}

.touch_screen_container {
  display: flex;
  flex-direction: column;
  .touch_screen_container__item {
    margin-bottom: 20px;

    &.title {
      margin-bottom: 20px;
    }

    .info_container {
      .title {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

.status_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .status_text {
    width: 80%;
    overflow-x: hidden;
  }

  .status_button {
    width: 15px;
    height: 15px;

    &.disable {
      button {
        color: #61606029;
      }
    }
    button {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      cursor: pointer;
      color: #4150b7;

      &:hover {
        transition: 0.4s;
        scale: (1.1);
      }
    }

    .stop_button {
      padding-top: 3px;
      position: relative;
      cursor: pointer;
      button {
        margin-right: 0px;
        margin-left: 3px;
      }

      .spinner_container {
        background: #4150b7;
        height: 7px;
        width: 7px;
        border-radius: 1px;

        &.waiting {
          background: #f0ad4e;
          transition: 0.4s;
        }
        &.error {
          background: #f44c4b;
          transition: 0.4s;
        }
        &.ok {
          background: #5bc367;
          transition: 0.4s;
        }
      }

      .spinner {
        width: 17px;
        height: 17px;
        animation: rotate 2s linear infinite;
        position: absolute;

        .circle {
          stroke: #4150b7;
          stroke-linecap: round;
          animation: dash 1.5s ease-in-out infinite;

          &.waiting {
            stroke: #f0ad4e;
            transition: 0.4s;
          }
          &.error {
            stroke: #f44c4b;
            transition: 0.4s;
          }
          &.ok {
            stroke: #5bc367;
            transition: 0.4s;
          }
        }
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes dash {
        0% {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: -124;
        }
      }
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
  }
}
</style>
